import 'datatables.net'
import 'bootstrap'

import instantsearch from 'instantsearch.js';
import { searchBox, hits } from 'instantsearch.js/es/widgets';
import algoliasearch from 'algoliasearch/lite';
import { connectHits } from 'instantsearch.js/es/connectors';

import { setAll, setCollapse, post, print_this, fetchPost, toTab, displayNotification } from './util'

if ($('#userq_form').length == 1) {
	initUserQs()
}

$(() => {
	initCalendar()
	initNotifications()
	initStaffModal()
	initSupport()
	initSearch()

	const pageTabs = ["#nav-providerq-tab", "#nav-training-tab", "#nav-assessment-tab"]
	const tabFix = (tabs) => {
		tabs.forEach(hash => {
			if (window.location.hash == hash) {
				$(`${hash}`).click()
			}
		});
	}
	tabFix(pageTabs)

	$(".scroll-to-top").on("click", () => {
		$('html, body').animate({ scrollTop: 0 }, 'fast')
	})

	if(window.location.hash == '#nav-questions-tab') toTab('nav-questions')

	if ($(window).width() > 480) {
		$(document).scroll(function () {
			$('#logo').css({
				opacity: $(this).scrollTop() > 150 ? "0%" : "100%",
				maxWidth: $(this).scrollTop() > 200 ? "300px" : "500px",
				maxHeight: $(this).scrollTop() > 200 ? "80px" : "130px"
			});
		});
	}

	// highlight nav bars
	$('#mainNav li a').filter(function () {
		let path = location.href
		path = location.href.replace(location.hash, "")
		return this.href === path;		
	}).addClass('active');
	
})

function initUserQs() {
	$("#userq_submit").on("click", async (e) => {
		e.preventDefault();

		if ($("#userq_form")[0].checkValidity() === false) {
			$("#userq_form")[0].reportValidity()
			return
		}

		let data = {
			name: $('#name').val(),
			email: $('#email').val(),
			subject: $('#subject').val(),
			question: $('#message').val(),
			type: "userq_submit"
		}

		const clear = () => {
			setAll(data, "")
			$('#userq_form :input').val('')
		}

		try {
			$('#contact_spinner').show()
			const res = await fetchPost('/form/submit', data)
			if (res.rc == 'OK') {
				clear()
				const $notification = $(".contact-form-success").show()
				setTimeout(() => $notification.fadeOut(500, () => $notification.remove()), 2000)
			} else {
				const $notification = $(".contact-form-error").show()
				setTimeout(() => $notification.fadeOut(500, () => $notification.remove()), 2000)
			}
		}
		catch (err) {
			console.error(err)
		} finally {
			$('#contact_spinner').hide()
		}
	})
}

const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
}

function initNotifications() {
	$("#notificationSubmit").off('click').on("click", async (e) => {
		try {
			e.preventDefault();
	
			//validate email
			const email = $('#userEmail').val();
			if (!isValidEmail(email)) throw 'BAD_EMAIL'
	
			let data = {
				email: $('#userEmail').val(),
				type: "contact_submit"
			}
	
			const clear = () => {
				setAll(data, "")
				$('#notificationForm :input').val('')
			}

			const res = await fetchPost('/form/submit', {type: 'contact_submit', email: email})
			if(res.rc != 'OK') throw res.rc

			const $notification = $("#newsletterSuccess").show()
			setTimeout(() => $notification.fadeOut(500, () => $notification.hide()), 2000)
			clear()
		} catch (err) { 
			console.error(err)
			if(err == 'BAD_EMAIL') {
				$("#newsletterErrorLang").text(' Please enter a valid email address')
			} else {
				$("#newsletterErrorLang").text(' You have not been added to the list.')
			}
			const $error = $("#newsletterError").show()
			setTimeout(() => $error.fadeOut(500, () => $error.hide()), 2000)
		}
	})
}

async function initCalendar() {

	const calShow = (calEvent) => {
		if (calEvent == null || calEvent == '') return

		$('#cal_modal').modal('show')

		$('.cal_close').on('click', () => {
			$('#cal_modal').modal('hide')
		})

		// $('#cal_modal_body').remove()
		$('#calevent_print').on('click', () => {
			let config = {
				title: 'Print Event',
				callback: function () {
					let $body = $('#cal_modal_body').clone();
					$body.find('#cal_link_container').remove()
					$body.prepend(`<h1>${calEvent.cal_title}</h2>`)
					$body.find('#calview_date').css("font-size", "25px")
					$body.find('#calview_time').css("font-size", "25px")
					$body.find('#calview_location').css("font-size", "25px")
					return $body;
				}
			};
			print_this(config)
		})

		$('.cal_links').remove()

		if (calEvent.cal_agenda != null) $("#cal_link_container").append($(`<li class="text-4"><i class="fa-solid fa-file"></i><a target='_blank' href="/data/get_doc/${calEvent.cal_agenda_guid}" class="cal_links ">${calEvent.cal_agenda_name}</a></li>`))
		if (calEvent.cal_material != null) $("#cal_link_container").append($(`<li class="text-4"><i class="fa-solid fa-file"></i><a target='_blank' href="/data/get_doc/${calEvent.cal_material_guid}" class="cal_links ">${calEvent.cal_material_name}</a></li>`))
		if (calEvent.cal_minutes != null) $("#cal_link_container").append($(`<li class="text-4"><i class="fa-solid fa-file"></i><a target='_blank' href="/data/get_doc/${calEvent.cal_minutes_guid}" class="cal_links ">${calEvent.cal_minutes_name}</a></li>`))
		if (!calEvent.cal_timezone) calEvent.cal_timezone = ''

		$('#calview_title').html(`${calEvent.cal_title}`)
		$('#calview_date').html(`Date: ${calEvent.cal_datepretty}`)
		$('#calview_time').html(`Time: ${calEvent.cal_time} ${calEvent.cal_timezone}`)
		$('#calview_location').html(`Location: ${calEvent.cal_location}`)
	}

	const eventId = $('#event_id').val() ?? null
	if (eventId) {
		try {
			const res = await fetchPost('/data/get_event', { id: eventId })
			setTimeout(() => calShow(res), 1000)
		} catch (e) {
			console.error('get event', e)
			displayNotification('Error Displaying Calendar Event', 'danger')
		}
	}

	setCollapse({ $btn: $('#calendar_filtercollapse'), $target: $('#calendar_filters') })
	$('#calevent_view_container').hide()

	const initCalendarDt = () => {

		const getCalFilters = () => ({
			type: $('#calfilter_type').val(),
			time: $('#calfilter_time').val()
		})

		const columnsInfo = [
			{
				data: 'cal_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'cal_title',
				header: 'Title',
			},
			{
				data: 'cal_typepretty',
				header: 'Type',
			},
			{
				data: 'cal_datepretty',
				header: 'Date',
			},
			{
				data: 'cal_timepretty',
				header: `Time`,
			},
			{
				data: 'cal_location',
				header: 'Location',
				visible: false,
			},
			{
				header: 'Meeting Details',
				data: '',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-more btn btn-primary btn-sm">Show More</button>',
			},
		]
		const headers = columnsInfo.map((col) => col.header)
		const calDt = $('#cal_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/calendar',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getCalFilters()
				}),
			},
			scrollX: true,
			paging: false,
			searching: false,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No events to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> events",
				infoEmpty: "Showing 0 to 0 of 0 events",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total events)",
				lengthMenu: "Show _MENU_ events",
			}
		})

		//Reload table when filters change
		$('#calendar_filters').on('change', () => {
			calDt.ajax.reload(undefined, false)
		})

		return calDt
	}
	let calDt = initCalendarDt()

	$('#caltable_container').on('click', '.show-more', async ({ target }) => {
		let rowData = calDt.row($(event.target).closest('tr')).data()
		const res = await fetchPost('/data/get_event', { id: rowData.cal_id })
		calShow(res)
	})

	$('#print').off('click').on('click', () => {
		let config = {
			title: 'Print Event',
			callback: function () {
				let $body = $('#print_container').clone();
				return $body;
			}
		};
		print_this(config)
	})

}

async function initStaffModal() {
	interface Staff {
		staff_id: number | null;
		staff_name: string;
		staff_title: string;
		staff_description: string;
		img_guid: string;
	}
	const staffMap: Map<HTMLElement, Staff> = new Map();

	const $staffTemplate = $('.staff_template').remove()
	const addStaff = (staff: Staff) => {
		const $staff = $staffTemplate.clone()
		const descriptionShort = staff.staff_description.slice(0, 100)
		$staff.find('.staff_name').text(staff.staff_name);
		$staff.find('.staff_title').text(staff.staff_title);

		$staff.find('.staff_description').on('click', () => {
			$('#staff_name').html(staff.staff_name)
			$('#staff_description_modal').text(staff.staff_description)
		})

		if (staff.img_guid) $staff.find('.staff_image').attr('src', `/data/get_img/${staff.img_guid}`)
		staffMap.set($staff[0], staff);
		return $staff;
	};

	const getAllStaffMembers = async () => {
		const res = await fetchPost('/data/get_staff')
		return res.staff
	}
	let staffMembers = await getAllStaffMembers()
	staffMembers.forEach(staff => {
		$('#staff_container').append(addStaff(staff));
	});
}

async function initSupport() {
	const questId = $(`#quest_id`).val()
	const provquestId = $(`#provquest_id`).val()

	if (provquestId && provquestId != 0) toTab('provqTabContent')

	if (questId && questId != 0) toTab('nav-questions')
	const $question = $(`#quest${questId ? questId : provquestId}`) as any
	$question.collapse('show')
	var targetPosition = $question.offset().top;
	$('html, body').animate({ scrollTop: targetPosition -= 200 }, 'slow');
}

const initSearch = () => {
	const searchButton = $('#headerSearchSend')

	const searchSite = () => {
		let query = String($('#headerSearch').val() ?? '').trim()
		if (query !== '') post('/data/search_site', { data: query })
	}

	searchButton.on('submit', async (e) => searchSite())

	$('#searchForm').on('submit', async (e) => {
		generateSearchResults()
		e.preventDefault()
	})

	$('#headerSearchForm').on('submit', async (e) => {
		searchSite()
		e.preventDefault()
	})

	const generateSearchResults = async () => {
		let query = String($('#searchQuery').val() ?? '').trim()
		if (query) {
			const SEARCH_CLIENT = 1;
			const { results } = await fetchPost('/data/search_site', { data: query, mode: SEARCH_CLIENT })
			const hits = results.hits
			const pagination = results.pagination

			$("#searchResultHeading").html(`${pagination['total']} search results for <strong>${query}</strong>`)
			$("#searchResultQuery").html(`Showing Results for <strong class='font-weight-extra-bold'>${query}</strong>`)
			$("#searchResultTotal").html(`${pagination.total} results found`)

			if (hits.length > 0) {

				const hitsList = $.map(hits, item => `
					<li>
						<div class="post-info">
							<a style="font-size: 24px;" href="${item.url}">${item.text}</a>
							<div class="post-meta mt-2">
								<span style="font-size: 18px;" class="text-dark text-uppercase font-weight-semibold">${item.name}</span>
								${item.page ? `<span style="font-size: 16px;">Page #${item.page}</span>` : ''}
							</div>
						</div>
					</li>
				`).join('');

				$("#hitsContainer").html(`
					<ul class="simple-post-list m-0">
						<div style="display: none !important;" class="d-flex justify-content-center align-items-center">
							<div class="spinner-border" style="width: 10rem; height: 10rem;" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
						${hitsList}
					</ul>
				`);

			} else  {
				$("#hitsContainer").html(`
					<h2 class="font-weight-normal text-8 mb-0">No Results...</strong></h2>
				`);
			}
		}
	}
}



